"use client";

import clsx from "clsx";
import dynamic from "next/dynamic";
import { forwardRef, useEffect, useState } from "react";
import type { ChangeEvent, InputHTMLAttributes, ForwardedRef, ForwardRefExoticComponent, RefAttributes, ReactNode } from "react";
import { inputConfig } from "@/components/Input/input.config";
import { useDebounceValue } from "@/utilities/with-debounce";
import { ValueMarkerInvalid } from "./components/ValueMarkerInvalid/ValueMarkerInvalid.component";
import { ValueMarkerValid } from "./components/ValueMarkerValid/ValueMarkerValid.component";
const DescriptionLabelComponent = dynamic(async () => import( /* webpackChunkName: "DescriptionLabel" */
"./components/DescriptionLabel/DescriptionLabel.component").then(module => module.DescriptionLabel));
type WithValidationProps = InputHTMLAttributes<HTMLInputElement> & {
  readonly ref?: ForwardedRef<HTMLInputElement>;
  readonly description?: string;
  readonly onClear?: () => void;
  readonly children?: ReactNode;
};
export function withValidation(Component: ForwardRefExoticComponent<Omit<WithValidationProps, "ref"> & RefAttributes<HTMLInputElement>>) {
  function ComponentWithValidation({
    className,
    onChange,
    defaultValue,
    description,
    children,
    ...rest
  }: WithValidationProps, ref: ForwardedRef<HTMLInputElement>) {
    const {
      millisecondsUntilFinishedTyping
    } = inputConfig();
    const [isDirty, setIsDirty] = useState<boolean>(Boolean(defaultValue ?? ""));
    const [value, setValue] = useState<string>(String(defaultValue ?? ""));
    const [debouncedValue] = useDebounceValue<string>(value, millisecondsUntilFinishedTyping);

    // Using clear button in WithClearButton will not trigger validation, unless we watch defaultValue
    useEffect(() => {
      setValue(String(defaultValue ?? ""));
    }, [defaultValue]);
    useEffect(() => {
      if (debouncedValue !== "") {
        setIsDirty(true);
      }
    }, [debouncedValue]);
    return <>
        <Component className={clsx(className, isDirty && "required:invalid:border-error-400 required:invalid:ring-0 hover:required:invalid:border-error-700 hover:required:invalid:ring-error-700 focus:required:invalid:ring-1 focus:required:invalid:ring-error-400 [&:not(:placeholder-shown):focus:invalid]:ring-1 [&:not(:placeholder-shown):focus:invalid]:ring-error-400 [&:not(:placeholder-shown):hover:invalid]:border-error-700 [&:not(:placeholder-shown):hover:invalid]:ring-error-700 [&:not(:placeholder-shown):invalid]:border-error-400 [&:not(:placeholder-shown):invalid]:ring-0", "peer")} ref={ref} value={value} onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onChange?.(event);
      }} onInvalid={event => event.preventDefault()} {...rest} data-sentry-element="Component" data-sentry-source-file="WithValidation.component.tsx" />

        {children}

        {isDirty ? <>
            <ValueMarkerInvalid className={clsx("absolute right-1 top-1/2 z-10 -translate-y-1/2", "peer-invalid:visible peer-invalid:opacity-100", "peer-valid:invisible peer-valid:opacity-0", "peer-placeholder-shown:invisible peer-placeholder-shown:opacity-0", "peer-disabled:invisible peer-disabled:opacity-0", "peer-read-only:invisible peer-read-only:opacity-0")} />
            <ValueMarkerValid className={clsx("absolute right-1 top-1/2 z-10 -translate-y-1/2", "peer-valid:visible peer-valid:opacity-100", "peer-invalid:invisible peer-invalid:opacity-0", "peer-placeholder-shown:invisible peer-placeholder-shown:opacity-0", "peer-disabled:invisible peer-disabled:opacity-0", "peer-read-only:invisible peer-read-only:opacity-0")} />
          </> : null}
        {description !== undefined ? <DescriptionLabelComponent className={clsx("absolute", isDirty && "peer-required:peer-invalid:text-error-500 peer-invalid:not-peer-placeholder-shown:text-error-500")}>
            {description}
          </DescriptionLabelComponent> : null}
      </>;
  }
  return forwardRef<HTMLInputElement, WithValidationProps>(ComponentWithValidation);
}