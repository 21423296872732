/**
 * Gets a unique path to the element starting with either "/body" or an identifier.
 * Inspired by http://stackoverflow.com/a/2631931.
 *
 * Example outputs:
 *     some_identifier
 *     /body/ul[1]/li[2]
 *     some_identifier/a[1]/img[1]
 *
 * To avoid those annoying-to-parse paths, make sure the input element has an id attribute.
 */
export function getUniqueDomPath(element: Element): string {
  if (element.id !== "") {
    return `#${element.id}`;
  }

  if (element === document.body) {
    return `/${element.tagName.toLowerCase()}`;
  }

  const siblings = element.parentNode?.childNodes ?? null;

  if (siblings === null) {
    return "";
  }

  for (const sibling of siblings) {
    const siblingElement = sibling;
    const siblingsArray = Array.prototype.slice.call(siblings);
    const siblingIndex = siblingsArray.indexOf(siblingElement);

    if (siblingElement === element && element.parentNode) {
      return `${getUniqueDomPath(
        element.parentNode as HTMLElement,
      )}/${element.tagName.toLowerCase()}[${siblingIndex + 1}]`;
    }
  }

  return "";
}
