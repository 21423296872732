"use client";

import { clsx } from "clsx";
import { useEffect, useId, type JSX } from "react";
import { evaulateScripts } from "../Tracking/cookiebot/CookiebotScript.component";
import { evaluateInlineScript } from "./evaluate-inline-script";
type HtmlContentProps = {
  readonly className?: string;
  readonly html: string;
  readonly tag?: keyof JSX.IntrinsicElements;
};
export function HtmlContent({
  className,
  html,
  tag = "div"
}: HtmlContentProps) {
  const id = useId();
  const TagComponent = tag;
  useEffect(() => {
    // We use our own evalutator to execute script tags ...
    evaluateInlineScript(`#${CSS.escape(id)}`);

    // ... and CookieBot for flipping iframes src attributes
    evaulateScripts();
  });
  return <TagComponent className={clsx(className, "prose prose-gray hyphens-manual break-words")} dangerouslySetInnerHTML={{
    __html: html
  }} id={id} data-sentry-element="TagComponent" data-sentry-component="HtmlContent" data-sentry-source-file="HtmlContent.component.tsx" />;
}