"use client";

export function withDebounce<
  DebouncedFunction extends (...parameters: ReadonlyArray<unknown>) => void,
>(debouncedFunction: DebouncedFunction, timeoutMilliseconds: number) {
  // eslint-disable-next-line functional/no-let -- The result of setTimeout() is to be stored in a local variable.
  let timerHandle: ReturnType<typeof setTimeout> | undefined = undefined;

  return (...parameters: Parameters<typeof debouncedFunction>) => {
    clearTimeout(timerHandle);
    timerHandle = setTimeout(
      () => debouncedFunction(parameters),
      timeoutMilliseconds,
    );
  };
}

// To make clear what this actually does, compared to it's fellows above, it has been aliased
export { useDebounceValue, useDebounceCallback } from "usehooks-ts";
