import type { SelfDescribingJson } from "@snowplow/browser-tracker";
import type { UserModel } from "@/contexts/user/models/user.model";
import type { TrackingDataModel } from "../../models/tracking-data-model.types";
import { getUniqueDomPath } from "../utilities/get-unique-dom-path";
import { snowplowCommmonPayload } from "./snowplow-common-payload";

type BuildSnowplowRecommendedArticleEventProps = {
  readonly articleIds: ReadonlyArray<number>;
  readonly element: Element | null;
  readonly trackingId: string | null;
  readonly trackingData: TrackingDataModel | null;
  readonly jpmuid: string;
  readonly user: UserModel;
};

export function snowplowRecommendedArticlePayload({
  articleIds,
  element,
  trackingId,
  trackingData,
  jpmuid,
  user,
}: BuildSnowplowRecommendedArticleEventProps): ReadonlyArray<SelfDescribingJson> | null {
  if (element === null || trackingData === null) return null;

  return [
    {
      data: {
        additional_data: [
          {
            key: "recommendation_api_response_id",
            value: trackingId,
          },
        ],
        classes: element.className.split(/\s+/u),
        id: getUniqueDomPath(element),

        tag_name: element.tagName,
        // We don't have any tracking labels, but we have to follow a JP schema
        timestamp: new Date().toISOString(),
        tracking_labels: [],
      },
      schema: "iglu:dk.jyllands-posten/element_in_view/jsonschema/1-0-0",
    },
    {
      data: {
        article_ids: articleIds,
      },
      schema: "iglu:dk.jyllands-posten/articles/jsonschema/1-0-0",
    },
    ...snowplowCommmonPayload(trackingData, jpmuid, user),
  ];
}
