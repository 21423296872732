"use client";

import clsx from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
import { LoginButton } from "@/components/Login/LoginButton/LoginButton.component";
import { Translation } from "@/components/Translation/Translation.component";
type MinimalHeaderLoginButtonContainerProps = {
  readonly className?: string;
};
export function MinimalHeaderLoginButtonContainer({
  className
}: MinimalHeaderLoginButtonContainerProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const redirectUrl = `${pathname}?${searchParams.toString()}`;
  return <LoginButton className={clsx(className, "min-h-0 p-1.5 lg:min-h-6 lg:px-3 lg:py-1")} redirectTo={redirectUrl} variant="outline" data-sentry-element="LoginButton" data-sentry-component="MinimalHeaderLoginButtonContainer" data-sentry-source-file="MinimalHeaderLoginButtonContainer.component.tsx">
      <Translation da="Log ind" de="Einloggen" en="Log in" no="Logg inn" sv="Logga In" data-sentry-element="Translation" data-sentry-source-file="MinimalHeaderLoginButtonContainer.component.tsx" />
    </LoginButton>;
}