"use client";

import { useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/user.context";
import { useJpmuid } from "../jpmuid/use-jpmuid.hook";
import { snowplowRecommendedArticlePayload } from "./snowplow-payload/snowplow-recommended-article-payload";
import { useSnowplowInViewTracking } from "./use-snowplow-in-view-event.hook";

type SnowplowRecommendationInViewTrackingProps = {
  readonly articleIds: ReadonlyArray<number>;
  readonly threshold?: number;
  readonly trackingId: string | null;
};

type SnowplowRecommendationInViewTrackingReturn = {
  readonly ref: (node: Element | null) => void;
};

const defaultThreshold = 0.8; // Default to track when the element is 80% visible in the viewport

export function useSnowplowRecommendationInViewTracking({
  articleIds,
  threshold = defaultThreshold,
  trackingId,
}: SnowplowRecommendationInViewTrackingProps): SnowplowRecommendationInViewTrackingReturn {
  const articleRef = useRef<Element | null>(null);
  const { trackingData } = useTrackingData();
  const { jpmuid } = useJpmuid();
  const { model: user } = useUser();

  const { ref, isIntersecting: isInView } = useIntersectionObserver({
    threshold,
  });

  const combinedRef = (node: Element | null) => {
    // Call the original ref callback
    ref(node);

    // Store the node in our own ref
    articleRef.current = node;
  };

  const recommendedArticleContext = snowplowRecommendedArticlePayload({
    articleIds,
    element: articleRef.current,
    jpmuid,
    trackingData,
    trackingId,
    user,
  });

  useSnowplowInViewTracking({
    action: "elements_in_view",
    category: "user_activity",
    context: recommendedArticleContext,
    hasTrackingEnabled: trackingId !== null && trackingData !== null,
    isInView,
  });

  return {
    ref: combinedRef,
  };
}
